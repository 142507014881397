.navbar {
    background-color: #ffffff;
    padding: 10px 20px;
    display: flex; /* Use flexbox for navbar layout */
    justify-content: space-between;
    align-items: center;/* Align items horizontally */
}
#bg-colour{
    background-color: #2865c2  ;
}
.dropdown {
    display: inline-block; /* Make dropdown display inline */
    position: relative; /* Needed for dropdown positioning */
}

.dropdown-content {
    display: none; /* Hide dropdown content by default */
    position: absolute; /* Position dropdown below button */
    background-color: #ffffff;
    min-width: 160px; /* Set dropdown width */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1; /* Ensure dropdown appears above other elements */
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ffffff;
}

.dropdown:hover .dropdown-content {
    display: block; /* Show dropdown content on hover */
}
.navbar a {
    text-decoration: none;
    color: black;
    font-size: 16px;
    margin-right: 20px;
}

.logout-button {
    background-color: transparent; /* Remove default button styling */
    border: none; /* Remove default button border */
    color: black; /* Text color */
    cursor: pointer; /* Set cursor to pointer on hover */
}

.sidebar {
    width: 200px;
    height: 100vh; /* 100vh for full viewport height */
    background-color: #ffffff;
    padding: 20px;
}

.sidebar a {
    text-decoration: none;
    color: black;
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
}

.sidebar a.active {
    background-color: #ffffff;
}

.content {
    flex: 1; /* Flex grows to fill remaining space */
    padding: 20px;
}

.weather-report {
    border: 2px solid #ffffff;
    padding: 20px;
    border-radius: 5px;
}

.city-name {
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
}

.date-time {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
}

.weather-icon {
    text-align: center;
    font-size: 40px;
    margin-bottom: 10px;
}

.weather-info {
    display: flex;
    flex-direction: column;
    
    margin-bottom: 10px;
}

.weather-info span {
    margin-bottom: 5px;
}
#card-center{
    width: 329%;
    display: flex;
    justify-content: center;
    align-items: center;

}


.weather-report h2 {
    margin-bottom: 10px;
}

.weather-icon {
    font-size: 40px;
    margin-bottom: 10px;
    
}




/* Optional styling for car-like details  */

#main{
    width: 100%;
    display: flex;
    align-items: center;
   


}

.search-container {
      display: flex;
      align-items: center;
      padding: 10px;
    }

    .search-container input[type="text"] {
      padding: 6px;
      margin-right: 10px;
      font-size: 16px;
      border: 1px solid #ddd;
      border-radius: 5px;
      width: 150px; /* Adjust width as needed */
    }

    .search-container button {
      padding: 6px 10px;
      font-size: 16px;
      border: none;
      border-radius: 5px;
      background-color: #007bff; /* Blue background */
      color: white;
      cursor: pointer; /* Change cursor to pointer on hover */
    }



    .button {
        display: inline-block;
        background: #ffffff;
        color: #000000;
        border:#fff;
        padding: 10px 20px;
        border-radius: 50px;
        box-shadow: 0px 17px 10px -10px rgba(0, 0, 0, 0.4);
        cursor: pointer;
        -webkit-transition: all ease-in-out 300ms;
        transition: all ease-in-out 300ms;
      }
      .button:hover {
        box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
        -webkit-transform: translate(0px, -10px) scale(1.05);
                transform: translate(0px, -10px) scale(1.05);
      }


      #nav-dropdown{
       
        display: inline-block;
       
       
        border:#fff;
        padding: 10px 20px;
        border-radius: 50px;
        
        cursor: pointer;
        -webkit-transition: all ease-in-out 300ms;
        transition: all ease-in-out 300ms;

      }

      #nav-dropdown:hover {
        box-shadow: 0px 37px 20px -20px rgba(0, 0, 0, 0.2);
        -webkit-transform: translate(0px, -10px) scale(1.1);
                transform: translate(0px, -10px) scale(1.1);
      }


      .mobile-img{
        padding-top: 100px;
        width: 180px;
height: 269.378px;

flex-shrink: 0;


      }